*{
    padding: 0;
    margin: 0;
  }
  body{
    padding: 0;
    margin: 0;
  }
  h1,
  h2 {
    font-weight: normal;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    /* display: inline-block; */
    margin: 0 10px;
  }
  
  a {
    /* color: #42b983; */
  }
  .hello{
    padding: 5%;
    height: calc(90vh - 280px);
    overflow: auto;
    padding-top: 65px;
    /* width: 70%; */
  }
  .chatright {
    /* max-width: 90%; */
    align-self: flex-end;
    /* max-width: 300px; */
    /* border: 3px solid #73AD21; */
    padding: 15px 20px;
    background: #4844f3;
    color: #FFF;
    /* border-radius: 20px; */
    white-space:normal;
    word-break:break-all;
    overflow:hidden;
    line-height: 30px;
    font-size: 16px;
    /* text-align: right; */
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  
  .chatleft {
    width: 90%;
    align-self: flex-start;
    /* float: left; */
    /* max-width: 300px; */
    /* border: 3px solid grey; */
    padding: 15px 20px;
    background: #f2f4f8;
    /* border-radius: 20px; */
    white-space:normal; 
    word-break:break-all;
    overflow:hidden;
    text-align: left;
    /* margin: 20px 0; */
    line-height: 30px;
    font-size: 16px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .guidance{
    width: 90%;
    align-self: flex-start;
    float: left;
    margin-top: 10px;
    /* max-width: 300px; */
    /* border: 3px solid grey; */
    padding: 15px 20px;
    background: #f2f4f8;
    /* border-radius: 20px; */
    white-space:normal; 
    word-break:break-all;
    overflow:hidden;
    text-align: left;
    /* margin: 20px 0; */
    line-height: 25px;
    font-size: 16px;
    border-radius: 10px;
  }
  .input-with-select{
    border-radius: 30px;
  }
  .chat_input{
    min-height: 50px;
    /* border-radius: 20px; */
    width: 100%;
    /* padding: 0 20px; */
    background: #fff;
    border: none;
    /* color: #f4f5f9; */
    font-size: 15px;
    overflow: auto;
  }
  .chat_input:focus {
     outline: none;
  }
  .chat_input::input-placeholder{
      color:#FFF;
  }
  .chat_input::-webkit-input-placeholder, textarea::-webkit-input-placeholder { 
   /* WebKit browsers */ 
    color:#FFF;
  } 
  .chat_input:-moz-placeholder, textarea:-moz-placeholder { 
  /* Mozilla Firefox 4 to 18 */ 
    color:#FFF;
  } 
  .chat_input::-moz-placeholder, textarea::-moz-placeholder { 
   /* Mozilla Firefox 19+ */ 
    color:#FFF;
  } 
  .chat_input:-ms-input-placeholder, textarea:-ms-input-placeholder { 
   /* Internet Explorer 10+ */ 
    color:#FFF;
  }
   .chat_input{
      width: 100%;
      resize:none;
      vertical-align:top;
      border: none;
      /* text-indent: 20px; */
      white-space: pre-line;
      line-height: 28px;
  }
  .el-input ::-webkit-input-placeholder{
    color: #bebebe;
  }
  ::-webkit-input-placeholder{
    color: #bebebe !important;
  }
  /*滚动条框设置*/
  .chat_input::-webkit-scrollbar{
      width: 2px;
      background-color: #fff;
  }
  /*滚动条设置*/
  .chat_input::-webkit-scrollbar-thumb{
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #4844f3;
  }
  
  .hello::-webkit-scrollbar{
      width: 2px;
      background-color: #fff;
  }
  /*滚动条设置*/
  .hello::-webkit-scrollbar-thumb{
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #4844f3;
  }
  input[type=file] {
    display: none;
  }
  .custom-button {
    display: inline-block;
    text-align: center;
    font-size: 14px;
    color: #4844f3;
    background-color: #fff;
    border-radius: 15px;
    cursor: pointer;
    border: none;
    /* border-radius: 20px; */
    padding: 9px;
    border:1px solid #4844f3;
  }
  
  .custom-button:hover {
    background-color: #4844f3;
    color: #fff;
  }
  .filename{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 140px;
    text-align: center;
  }
  .chat_top{
    display:flex;
    align-items:center;
    justify-content: space-between;
    box-shadow:0px -2px 5px 0px rgba(39,39,39,0.16);
    filter:blur(0px);
    -webkit-filter: blur(0px);
/*    opacity: 0.8;*/
    height:50px;
    position:absolute;
    left: 0;
    top: 0;
    width: calc(100% - 40px);
    padding: 5px 20px;
    z-index: 99;
  }
  .filter_css{
    background: inherit; 
    position: absolute;
    height:60px;
    width: 100%;
    left: 0;  
    right: 0;
    top: 0;
    bottom: 0;
    box-shadow: inset 0 0 0 200px rgba(255,255,255,0.3);
    /* filter: blur(10px); */
    backdrop-filter: blur(25px);
    z-index: 9;
    opacity: 0.99;
  }
  .css-ahj2mt-MuiTypography-root{
    font-size: 13px !important;
    min-width: 27px !important;
  }
  .css-53tbrc-MuiSlider-root{
    color: rgb(72, 68, 243) !important;
  }
 
  .chat_loading i{
    background-color: #4844f3 !important;
  }
  .css-4gph6d-MuiTableRow-root{
    cursor:pointer;
  }
  .file_lists::-webkit-scrollbar{
    width: 10px;
    height: 10px;
  }
  .file_lists::-webkit-scrollbar{
    
  }
  .subjoin_problem{
    box-sizing: border-box;
    color: var(--b-1,#1a2029);
    /* font-family: PingFang SC; */
    font-size: 12px;
    /* font-style: normal; */
    /* font-weight: 600; */
    line-height: 20px;
    text-align: left;
    cursor: pointer;
    border: 1px solid transparent;
    display: flex;
    padding: 6px 12px 6px 15px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: rgba(242,244, 248, 0.6);
  }