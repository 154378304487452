/* table{
    border-collapse: collapse;
} */
.mark_css table{
    display: table;
    text-align: left;
    border-collapse: collapse;
}
.mark_css tbody{
    border: 0;
}
.mark_css table tr{
    border: 0;
    border-top: 1px solid #ccc;
    background-color: white;
}
.mark_css table tr th{
    font-weight: bold;
    background-color:#4844f3;
}
.mark_css table tr th, .mark_css table tr td{
    font-size: 16px;
    border: 1px solid #ccc;
    padding:5px 10px
}