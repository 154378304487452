.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: rgba(0, 0, 0, 0.2) !important;
    border-width: 1px !important;
}
.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: rgba(0, 0, 0, 0.2) !important;
    border-width: 1px !important;
}
.css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: rgba(0, 0, 0, 0.2) !important;
    border-width: 1px !important;
}
 .MuiOutlinedInput-notchedOutline{
    border-color: rgba(0, 0, 0, 0.2) !important;
    border-width: 1px !important;
}
.filename{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 200px;
    text-align: center;
}
.file_loading i{
    background-color: #4844f3 !important;
}

/* 在你的CSS文件中添加以下样式 */
.draggable-item {
    transition: transform 0.3s ease;
  }
  
  .dragging {
    transform: scale(1.1); /* 当拖动时放大元素 */
    opacity: 0.7; /* 可选：拖动时降低透明度 */
  }
  .mark_css img{
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .mark_css h1{
    text-align: center;
  }
  .mark_css th{
    background-color:#4844f3;
    color: #fff;
  }
#demo-select-small-label{
  color: #4844f3;
}
.cover_dialog .MuiPaper-root{
  max-width: 100%;
  width: 80%;
  /* height: 100%; */
}
.generate_spin .ant-spin-dot-item{
  /* background-color: #fff; */
}