.mermaid_loading i{
    background-color: #fff !important
}

.mermaid .label .label__title .label__logo ,.mermaid .node .label .label__logo {
    display: none !important;
}

.chat_input {
    min-height: 50px;
    /* border-radius: 20px; */
    width: 100%;
    /* padding: 0 20px; */
    background: #fff;
    border: none;
    /* color: #f4f5f9; */
    font-size: 15px;
    overflow: auto;
}

.chat_input:focus {
    outline: none;
}

.chat_input::input-placeholder {
    color: #FFF;
}

.chat_input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #FFF;
}

.chat_input:-moz-placeholder,
textarea:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #FFF;
}

.chat_input::-moz-placeholder,
textarea::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #FFF;
}

.chat_input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #FFF;
}

.chat_input {
    width: 100%;
    resize: none;
    vertical-align: top;
    border: none;
    /* text-indent: 20px; */
    white-space: pre-line;
    line-height: 28px;
}

.el-input ::-webkit-input-placeholder {
    color: #bebebe;
}

::-webkit-input-placeholder {
    color: #bebebe !important;
}

/*滚动条框设置*/
.chat_input::-webkit-scrollbar {
    width: 2px;
    background-color: #fff;
}

/*滚动条设置*/
.chat_input::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #4844f3;
}