.horizontal-controls {
  display: flex !important;
  justify-content: center !important;
  position: absolute;
  bottom: 10px !important;
  left: 50px !important;
  transform: translateX(-50%) !important;
  flex-direction: row;
}
.minimap-container {
  position: absolute !important;
  bottom: 40px !important;
  left: 30px !important;
  z-index: 5; /* 确保它位于其他元素之上 */
  width: 102px !important;
  height: 72px !important;
}
.minimap-container .react-flow__minimap .react-flow__minimap-svg{
  width: 102px;
  height: 72px;
  
}