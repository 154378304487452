.panel_text::selection{
    background: transparent;
    color: inherit;   
}
.panel_text::-moz-selection{
    background: transparent;
    color: inherit;  
}
.panel_text:focus{
    outline: none;
}